import React from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
// @ts-ignore
import Amplify from 'aws-amplify';
import * as Sentry from '@sentry/react';
import App from './pages/App';
import store from './store';
import './i18n';
import awsConfig from './aws-exports';
import sentryConfig from './sentry-exports';

declare global {
  interface Window {
    dataLayer?: Array<any>;
  }
}

Amplify.configure({
  ...awsConfig,
  cookieOptions: {
    domain: '.heirloom.cloud',
    secure: true,
    sameSite: 'strict', // Или 'none', если нужен cross-site
    path: '/',
    expires: 365
  }
});
Sentry.init(sentryConfig);

const container = document.getElementById('root');
const root = createRoot(container);
console.log('Version: 0.4.3');

root.render(
  <Provider store={store}>
    <App />
  </Provider>
);
